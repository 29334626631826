import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';


const headerColor = '#240400';

const LuckyStarPage = ({ data }) => (
  <>
    <SEO
      title="Bonus de casino de Neosurf au LuckyStar – 125% et 125 tours"
      description=" Profite du bonus exclusif du Casino LuckyStar de Casino Neosurf.  Dépose avec le Neosurf et obtiens 125% de bonus et 125 Tours Gratuits."
      alternateLanguagePages={[
        {
          path: 'casino/luckystar',
          language: 'en'
        },
        {
          path: 'casino/luckystar',
          language: 'fr'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="Casino en ligne LuckyStar – Casino amical – Neosurf" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller">Casino LuckyStar &amp; Bonus exclusifs de Neosurf</h1>
            <p className="textColorLight">Guidé par la devise "<strong>Les joueurs heureux sont la meilleure publicité</strong>", le Casino LuckyStar représente une véritable vedette dans l’industrie du jeu en ligne. Plus de 1000 jeux de casino, une large gamme de méthodes de paiement, y compris le Neosurf et un soutien clientèle primé, garantissent une superbe expérience de jeu. Inscris-toi aujourd’hui et réclame ton <strong>Bonus de Casino spécial de NEOSURF</strong>.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">BONUS DE BIENVENUE SPÉCIAL NEOSURF DE LUCKYSTAR</h2>
            <ul>
              <li>125% jusqu’à 500 $ / € &amp; 125 tours gratuits sans mise</li>
              <li>Les tours gratuits sont sur The Golden Owl of Athena /Fruits Zen / Mega Gems (le créneau que tu ouvres en premier parmi des ceux proposés)</li>
              <li>Utilise le <strong>code: SURF125+125</strong></li>
              <li>Le dépôt minimum est de 20 $/€<br />La cagnotte est fois trente (x30)</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" target="_blank" rel="noopener noreferrer">OUVRIR CASINO LUCKYSTAR</a>
            </p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="Bonus de casino de Neosurf le mieux noté" />
        </div>
      </div>
    </div>
  </>
)

export default LuckyStarPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/luckystar/neosurf_lucky-star.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/luckystar/neosurf_lucky-star_promo.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
